import React, { useEffect, useState } from "react";
import PDF from "../../../../components/icons/PDF.svg";
import axios from "axios";
import Loader from "../Loader";
import { ReduxContext } from "contexts/redux/ReduxState";

const AllApiDocs = () => {
  const [pdfData, setPdfData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user  } = React.useContext(ReduxContext);
  const [transactionLoaderState, setTransactionLoaderState] = useState(false);

  const pdfapi = async () => {
    setTransactionLoaderState(true);
    try {
      const requestBody = {
        merchantId: user?.merchantId,
      };
      const response = await axios.post(
        "https://bumppy.in/cubexis.in/api/getApiDocs.php",
        requestBody
      );

      if (response.data.status === "00") {
        setPdfData(response.data.docs);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching PDF data:", error);
    }
    setTransactionLoaderState(false);
  };

  useEffect(() => {
    pdfapi();
  }, []);

  const handleDownload = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div>
    {transactionLoaderState ? (
      <Loader />
    ) : (
    <div style={{ display: "flex", justifyContent: "center" }}>
    <div
      className="row"
      style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
    >
      {pdfData.map((item, index) => (
        <div
          key={index}
          style={{
            padding: "0.5rem", 
            flex: "1 0 18%",   
            maxWidth: "200px",
            margin: "0.5rem",
          }}
        >
          <div
            style={{
              borderRadius: "10px",
              backgroundColor: "#ffffff",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
              padding: "1rem", // Reduce padding inside the card
              transition: "transform 0.2s",
              cursor: "pointer",
            }}
            onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
            onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
          >
            <img
              src={PDF}
              alt="PDF Icon"
              height={60} // Reduce image height to fit the smaller card
              style={{ objectFit: "contain", marginBottom: "0.75rem" }} // Adjust margin
            />
            <div style={{ fontWeight: "bold", marginTop: "0.75rem", fontSize: "0.9rem" }}> {/* Smaller font size */}
              {item.doc_name}
            </div>
            <div style={{ fontSize: "0.8rem", color: "#888" }}>{item.indate}</div>
            <button
              style={{
                marginTop: "0.75rem",  // Adjust margin for better spacing
                padding: "0.4rem 0.8rem", // Smaller button size
                backgroundColor: "#007bff",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                fontSize: "0.75rem", // Reduce font size for the button
                cursor: "pointer",
              }}
              onClick={() => handleDownload(item.doc_url)}
            >
              View
            </button>
          </div>
        </div>
      ))}
    </div>
  </div>
   )}
      </div>
  );
};

export default AllApiDocs;
