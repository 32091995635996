'use client';
/* eslint-disable */
import { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
// import { Link } from '@chakra-ui/react';
// import { Link } from 'react-router';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
// Custom components
import Card from 'components/card/Card';
import Menu from 'components/menu/MainMenu';
import { AndroidLogo, AppleLogo, WindowsLogo } from 'components/icons/Icons';
import axios from 'axios';
import * as React from 'react';
import Loader from '../Loader';
import { ReduxContext } from 'contexts/redux/ReduxState';
// Assets

const columnHelper = createColumnHelper();

// const columns = columnsDataCheck;
export default function AllClient(props) {
  const { user } = React.useContext(ReduxContext);
  const [transactionLoaderState, setTransactionLoaderState] = useState(false);
  const [data, setData] = useState([]);
  const [allMerchantList, setallMerchantList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // const { tableData } = props;
  const [sorting, setSorting] = useState([]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const iconColor = useColorModeValue('secondaryGray.500', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  const AllMerchentListAPi = async () => {
    setTransactionLoaderState(true);
    const rawData = {
      merchantId: user?.merchantId,
    };

    try {
      const response = await axios.post(
        `https://cubexis.in/api/sdkClientGetAllMerchants.php`,
        rawData,
      );

      if (response.status === 200) {
        if (response.data.status == '00') {
          setallMerchantList(response.data.data);
        }
      } else {
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error('Error fetching report data:', error);
      setTransactionLoaderState(false);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  useEffect(() => {
    AllMerchentListAPi();
  }, []);

  const headers = [
    { id: 'id', label: 'Sr' },
    { id: 'merchantId', label: 'Merchant Id' },
    { id: 'businessName', label: 'Business Name' },
    { id: 'Unsettled Amount', label: 'Unsettled Amount' },
    { id: 'date', label: 'OnBoarding Date' },
  ];

  return transactionLoaderState ? (
    <Loader />
  ) : (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
      style={{ marginTop: '5rem' }}
    >
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
          className="mt-4"
        >
          All Client
        </Text>
      </Flex>
      <Box>
        <Table variant="simple" color="gray.500" mb="24px" mt="12px">
          <Thead>
            <Tr>
              {headers.map((header) => (
                <Th
                  key={header.id}
                  pe="10px"
                  borderColor={borderColor}
                  cursor="pointer"
                >
                  <Flex
                    justifyContent="space-between"
                    align="center"
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color="gray.400"
                    style={{ justifyContent: 'center' }}
                  >
                    {header.label}
                  </Flex>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {allMerchantList.map((item, index) => (
              <Tr
                key={item._id}
                style={{
                  border: 'none',
                  height: '3rem',
                  backgroundColor: 'white',
                  color: 'black',
                }}
              >
                <Td
                  style={{ border: 'none' }}
                  className="text-center align-middle"
                  fontSize={{ sm: '10px', lg: '13px' }}
                >
                  {index + 1}
                </Td>
                <Td
                  style={{ border: 'none' }}
                  className="text-center align-middle"
                  fontSize={{ sm: '10px', lg: '13px' }}
                >
                  {item.merchantId}
                </Td>
                <Td
                  style={{ border: 'none' }}
                  className="text-center align-middle"
                  fontSize={{ sm: '10px', lg: '13px' }}
                >
                  {' '}
                  {item.BusinessName}
                </Td>
                <Td
                  style={{ border: 'none' }}
                  className="text-center align-middle"
                  fontSize={{ sm: '10px', lg: '13px' }}
                >
                  {item.PaymentAPIBalance || 'NA'}
                </Td>
                <Td
                  style={{ border: 'none' }}
                  className="text-center align-middle"
                  fontSize={{ sm: '10px', lg: '13px' }}
                >
                  {item.onboardingDate || 'NA'}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
}
