'use client';
import { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Input,
  InputGroup,
  Tr,
  FormLabel,
  InputRightElement,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { Link } from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Swal from 'sweetalert2';
import { RiEyeCloseLine } from "react-icons/ri";
import Card from 'components/card/Card';
import Menu from 'components/menu/MainMenu';
import { AndroidLogo, AppleLogo, WindowsLogo } from 'components/icons/Icons';
import axios from 'axios';
import * as React from 'react';
import './CreateClient.css'
import Loader from '../Loader';
// Assets

const columnHelper = createColumnHelper();

// const columns = columnsDataCheck;
export default function CreateClient(props) {
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const [allMerchantList, setallMerchantList] = useState([]);
  const brandStars = useColorModeValue("brand.500", "brand.400");
  // const [show, setShow] = React.useState(false);
  const textColorSecondary = "gray.400";
  const [formData, setFormData] = useState({
    businessname: "",
    email: "",
    password: "",
    confirmPassword: "",
    mobile: "",
  });
  
  const [formError, setFormError] = useState({
    businessname: "",
    email: "",
    password: "",
    confirmPassword: "",
    mobile: "",
});

  // handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  
  // toggle password visibility (assuming `show` is part of your state)
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [error, setError] = useState(null);
  // const { tableData } = props;
  const [sorting, setSorting] = useState([]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const iconColor = useColorModeValue('secondaryGray.500', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  // let defaultData = tableData;
console.log(formData);

const handleSubmit = (e) => {
  e.preventDefault();
  if (formData.password != formData.confirmPassword) {
      setFormError({ ...formError, password: ["Password does not match"] });
      return;
  }
  // clearForm();
  createMerchantApi();
};

  const createMerchantApi = async () => {
    // setLoading(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        // parentId: user?.merchantId,
        parentId: "2",
        companyName: formData.businessname,
        usertype: "1",
        mobile: formData.mobile,
        password: formData.password,
        email: formData.email,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://cubexis.in/api/merchantRegistration2.php",
        requestOptions
      );

      const data = await res.json();

      console.log(data);

      if (data.status === "00") {
        // setLoading(false);
        Swal.fire("Success", "Client Created Successfully");
        // clearForm();
        console.log("hiii");

      } else {
        if (data.errors) {
          // setFormError(data.errors);
          console.log("hiii");
        }
      }
    } catch (error) {
      console.error("Error fetching Form data:", error);
    }

  };

  return (
    <Card flexDirection="column" w="100%" px="0px" overflowX={{ sm: "scroll", lg: "hidden" }} style={{margin:"0"}}>
    <Box>
      <div className="full-width-container" style={{marginLeft:"7rem"}}>
        <div className="row">
          <div className="col-12 col-lg-6 offset-lg-3">
            <form>
              <div className="form-title">Create Client</div>

              <div className="row">
                <div className="col-12 col-lg-6 mb-3">
                  <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                    Email<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    fontSize="sm"
                    ms={{ base: "0px", md: "0px" }}
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="mail@example.com"
                    fontWeight="500"
                    size="lg"
                  />
                </div>

                <div className="col-12 col-lg-6 mb-3">
                  <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                    Mobile Number<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: "0px", md: "0px" }}
                    type="number"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleInputChange}
                    placeholder="********99"
                    // mb="24px"
                    fontWeight="500"
                    size="lg"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-lg-6 mb-3">
                  <FormLabel ms="4px" fontSize="sm" fontWeight="500" color={textColor} display="flex">
                    Password<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      isRequired={true}
                      fontSize="sm"
                      placeholder="Min. 8 characters"
                      mb="24px"
                      size="lg"
                      name="password"
                      type={show ? "text" : "password"}
                      variant="auth"
                      value={formData.password}
                      onChange={handleInputChange}
                    />
                    <InputRightElement display="flex" alignItems="center" mt="4px">
                      <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: "pointer" }}
                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                        onClick={handleClick}
                      />
                    </InputRightElement>
                  </InputGroup>
                </div>

                <div className="col-12 col-lg-6 mb-3">
                  <FormLabel ms="4px" fontSize="sm" fontWeight="500" color={textColor} display="flex">
                    Confirm Password<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      isRequired={true}
                      fontSize="sm"
                      placeholder="Confirm Password"
                      mb="24px"
                      size="lg"
                      name="confirmPassword"
                      type={show ? "text" : "password"}
                      variant="auth"
                      value={formData.confirmPassword}
                      onChange={handleInputChange}
                    />
                    <InputRightElement display="flex" alignItems="center" mt="4px">
                      <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: "pointer" }}
                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                        onClick={handleClick}
                      />
                    </InputRightElement>
                  </InputGroup>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-lg-6 mb-3">
                  <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                    Business Name<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: "0px", md: "0px" }}
                    type="text"
                    name="businessname"
                    placeholder="Enter Business Name"
                    mb="24px"
                    fontWeight="500"
                    size="lg"
                    value={formData.businessname}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="button-container row">
                <button
                  className="col btn btn-create rounded-2"
                  style={{ color: "white", background: "#161D6F" }}
                  onClick={handleSubmit}
                >
                  Create Client
                </button>
                <button className="col btn btn-clear rounded-2" style={{ color: "white", background: "#C80036" }}>
                  Clear Form
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Box>
  </Card>

  );
}
