import React from 'react';
import { Icon } from '@chakra-ui/react';
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
} from 'react-icons/md';

// Admin Imports
import MainDashboard from 'views/admin/default';
import NFTMarketplace from 'views/admin/marketplace';
import Profile from 'views/admin/profile';
import DataTables from 'views/admin/dataTables';
import RTL from 'views/admin/rtl';
// Auth Imports
import SignInCentered from 'views/auth/signIn';
import AllClient from 'views/admin/dataTables/components/AllClient';
import CreateClient from 'views/admin/dataTables/components/CreateClient';
import Developer from 'views/admin/dataTables/components/Developer';
import LoadRequest from 'views/admin/dataTables/components/LoadRequest';
import TransactionDetails from 'views/admin/dataTables/components/TransactionDetails';
import PayinReport from 'views/admin/dataTables/components/PayinReport';
import MyAccount from 'views/admin/dataTables/components/MyAccount';
import ClientLedger from 'views/admin/dataTables/components/ClientLedger';
import Settlement from 'views/admin/marketplace/settlement';

const routes = [
  {
    name: 'Main Dashboard',
    layout: '/admin',
    path: '/dashboard',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <MainDashboard />,
  },
  {
    name: 'Client Ledger',
    layout: '/admin',
    path: '/payin_ledger',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <ClientLedger />,
  },
  {
    name: 'Settlements',
    layout: '/admin',
    path: '/settlement_process',
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: <NFTMarketplace tableData={[]}  />,
    secondary: true,
  },

  
 
  {
    name: 'All clients',
    layout: '/admin',
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/all_clients',
    component: <AllClient />,
  },
  {
    name: 'Create Client',
    layout: '/admin',
    path: '/create_client',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <CreateClient />,
  },
  {
    name: 'Developer',
    layout: '/admin',
    path: '/developer',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <Developer />,
  },
  {
    name: 'Load Request',
    layout: '/admin',
    path: '/load_request',
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    component: <LoadRequest />,
  },
  {
    name: 'Pay-In',
    layout: '/admin',
    path: '/payin_report',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <PayinReport />,
  },
  {
    name: 'Transaction Details',
    layout: '/admin',
    path: '/transaction_query',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <TransactionDetails />,
  },
  {
    name: 'Pay-out',
    layout: '/admin',
    path: '/payout',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <MyAccount />,
  },
  {
    name: 'Logout ',
    layout: '/admin',
    path: '/',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: <SignInCentered />,
  }
  
];



export default routes;
